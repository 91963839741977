// Some convenient tools to get you started…
import ReplaceObfuscatedEmailAddresses from "./components/ReplaceObfuscatedEmailAddresses";
// import * as MailChimp from "./components/mailchimp";

/*!
 * animsition v4.0.2
 * A simple and easy jQuery plugin for CSS animated page transitions.
 * http://blivesta.github.io/animsition
 * License : MIT
 * Author : blivesta (http://blivesta.com/)
 */
;(function (factory) {
  'use strict';
  if (typeof define === 'function' && define.amd) {
    define(['jquery'], factory);
  } else if (typeof exports === 'object') {
    module.exports = factory(require('jquery'));
  } else {
    factory(jQuery);
  }
}(function ($) {
  'use strict';
  var namespace = 'animsition';
  var loaded = false;
  $(window).on('load', function() {
    loaded = true;
  });
  var __ = {
    init: function(options){
      options = $.extend({
        inClass               :   'fade-in',
        outClass              :   'fade-out',
        inDuration            :    1500,
        outDuration           :    800,
        linkElement           :   '.animsition-link',
        // e.g. linkElement   :   'a:not([target="_blank"]):not([href^="#"])'
        loading               :    true,
        loadingParentElement  :   'body', //animsition wrapper element
        loadingClass          :   'animsition-loading',
        loadingInner          :   '', // e.g '<img src="loading.svg" />'
        timeout               :   false,
        timeoutCountdown      :   5000,
        onLoadEvent           :   true,
        browser               : [ 'animation-duration', '-webkit-animation-duration'],
        // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
        // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
        overlay               :   false,
        overlayClass          :   'animsition-overlay-slide',
        overlayParentElement  :   'body',
        transition            :   function(url){ window.location.href = url; }
      }, options);

      __.settings = {
        timer: false,
        data: {
          inClass: 'animsition-in-class',
          inDuration: 'animsition-in-duration',
          outClass: 'animsition-out-class',
          outDuration: 'animsition-out-duration',
          overlay: 'animsition-overlay'
        },
        events: {
          inStart: 'animsition.inStart',
          inEnd: 'animsition.inEnd',
          outStart: 'animsition.outStart',
          outEnd: 'animsition.outEnd'
        }
      };

      // Remove the "Animsition" in a browser
      // that does not support the "animaition-duration".
      var support = __.supportCheck.call(this, options);

      if(!support && options.browser.length > 0){
        if(!support || !this.length){
          // If do not have a console object to object window
          if (!('console' in window)) {
            window.console = {};
            window.console.log = function(str){ return str; };
          }
          if(!this.length) console.log('Animsition: Element does not exist on page.');
          if(!support) console.log('Animsition: Does not support this browser.');
          return __.destroy.call(this);
        }
      }

      var overlayMode = __.optionCheck.call(this, options);

      if (overlayMode && $('.' + options.overlayClass).length <= 0) {
        __.addOverlay.call(this, options);
      }

      if (options.loading && $('.' + options.loadingClass).length <= 0) {
        __.addLoading.call(this, options);
      }

      return this.each(function(){
        var _this = this;
        var $this = $(this);
        var $window = $(window);
        var $document = $(document);
        var data = $this.data(namespace);

        if (!data) {
          options = $.extend({}, options);

          $this.data(namespace, { options: options });

          if(options.timeout) __.addTimer.call(_this);

          if(options.onLoadEvent) {
            if(loaded) {
              if(__.settings.timer) clearTimeout(__.settings.timer);
              __.in.call(_this);
            } else {
              $window.on('load', function(e) {
                if(__.settings.timer) clearTimeout(__.settings.timer);
                __.in.call(_this);
              });
            }
          }

          $window.on('pageshow.' + namespace, function(event) {
            if(event.originalEvent.persisted) __.in.call(_this);
          });

          // Firefox back button issue #4
          $window.on('unload.' + namespace, function() { });

          $document.on('click.' + namespace, options.linkElement, function(event) {
            event.preventDefault();
            var $self = $(this);
            var url = $self.attr('href');

            // middle mouse button issue #24
            // if(middle mouse button || command key || shift key || win control key)
            if (event.which === 2 || event.metaKey || event.shiftKey || navigator.platform.toUpperCase().indexOf('WIN') !== -1 && event.ctrlKey) {
              window.open(url, '_blank');
            } else {
              __.out.call(_this, $self, url);
            }

          });
        }
      }); // end each
    },

    addOverlay: function(options){
      $(options.overlayParentElement)
        .prepend('<div class="' + options.overlayClass + '"></div>');
    },

    addLoading: function(options){
      $(options.loadingParentElement)
        .append('<div class="' + options.loadingClass + '">' + options.loadingInner + '</div>');
    },

    removeLoading: function(){
      var $this     = $(this);
      var options   = $this.data(namespace).options;
      var $loading  = $(options.loadingParentElement).children('.' + options.loadingClass);

      $loading.fadeOut().remove();
    },

    addTimer: function(){
      var _this = this;
      var $this = $(this);
      var options = $this.data(namespace).options;

      __.settings.timer = setTimeout(function(){
        __.in.call(_this);
        $(window).off('load.' + namespace);
      }, options.timeoutCountdown);
    },

    supportCheck: function(options){
      var $this = $(this);
      var props = options.browser;
      var propsNum = props.length;
      var support  = false;

      if (propsNum === 0) {
        support = true;
      }
      for (var i = 0; i < propsNum; i++) {
        if (typeof $this.css(props[i]) === 'string') {
          support = true;
          break;
        }
      }
      return support;
    },

    optionCheck: function(options){
      var $this = $(this);
      var overlayMode;
      if(options.overlay || $this.data(__.settings.data.overlay)){
        overlayMode = true;
      } else {
        overlayMode = false;
      }
      return overlayMode;
    },

    animationCheck : function(data, stateClass, stateIn){
      var $this = $(this);
      var options = $this.data(namespace).options;
      var dataType = typeof data;
      var dataDuration = !stateClass && dataType === 'number';
      var dataClass = stateClass && dataType === 'string' && data.length > 0;

      if(dataDuration || dataClass){
        data = data;
      } else if(stateClass && stateIn) {
        data = options.inClass;
      } else if(!stateClass && stateIn) {
        data = options.inDuration;
      } else if(stateClass && !stateIn) {
        data = options.outClass;
      } else if(!stateClass && !stateIn) {
        data = options.outDuration;
      }
      return data;
    },

    in: function(){
      var _this = this;
      var $this = $(this);
      var options = $this.data(namespace).options;
      var thisInDuration = $this.data(__.settings.data.inDuration);
      var thisInClass = $this.data(__.settings.data.inClass);
      var inDuration = __.animationCheck.call(_this, thisInDuration, false, true);
      var inClass = __.animationCheck.call(_this, thisInClass, true, true);
      var overlayMode = __.optionCheck.call(_this, options);
      var outClass = $this.data(namespace).outClass;

      if(options.loading) __.removeLoading.call(_this);

      if(outClass) $this.removeClass(outClass);

      if(overlayMode) {
        __.inOverlay.call(_this, inClass, inDuration);
      } else {
        __.inDefault.call(_this, inClass, inDuration);
      }
    },

    inDefault: function(inClass, inDuration){
      var $this = $(this);

      $this
        .css({ 'animation-duration' : inDuration + 'ms' })
        .addClass(inClass)
        .trigger(__.settings.events.inStart)
        .animateCallback(function(){
          $this
            .removeClass(inClass)
            .css({ 'opacity' : 1 })
            .trigger(__.settings.events.inEnd);
        });
    },

    inOverlay: function(inClass, inDuration){
      var $this = $(this);
      var options = $this.data(namespace).options;

      $this
        .css({ 'opacity' : 1 })
        .trigger(__.settings.events.inStart);

      $(options.overlayParentElement)
        .children('.' + options.overlayClass)
        .css({ 'animation-duration' : inDuration + 'ms' })
        .addClass(inClass)
        .animateCallback(function(){
          $this
            .trigger(__.settings.events.inEnd);
        });
    },

    out: function($self, url){
      var _this = this;
      var $this = $(this);
      var options = $this.data(namespace).options;
      var selfOutClass = $self.data(__.settings.data.outClass);
      var thisOutClass = $this.data(__.settings.data.outClass);
      var selfOutDuration = $self.data(__.settings.data.outDuration);
      var thisOutDuration = $this.data(__.settings.data.outDuration);
      var isOutClass = selfOutClass ? selfOutClass : thisOutClass;
      var isOutDuration = selfOutDuration ? selfOutDuration : thisOutDuration;
      var outClass = __.animationCheck.call(_this, isOutClass, true, false);
      var outDuration = __.animationCheck.call(_this, isOutDuration, false, false);
      var overlayMode = __.optionCheck.call(_this, options);

      $this.data(namespace).outClass = outClass;

      if(overlayMode) {
        __.outOverlay.call(_this, outClass, outDuration, url);
      } else {
        __.outDefault.call(_this, outClass, outDuration, url);
      }
    },

    outDefault: function(outClass, outDuration, url){
      var $this = $(this);
      var options = $this.data(namespace).options;

      // (outDuration + 1) | #55 outDuration: 0 crashes on Safari only
      $this
        .css({ 'animation-duration' : (outDuration + 1)  + 'ms' })
        .addClass(outClass)
        .trigger(__.settings.events.outStart)
        .animateCallback(function(){
          $this.trigger(__.settings.events.outEnd);
          options.transition(url);
        });
    },


    outOverlay: function(outClass, outDuration, url){
      var _this = this;
      var $this = $(this);
      var options = $this.data(namespace).options;
      var thisInClass = $this.data(__.settings.data.inClass);
      var inClass = __.animationCheck.call(_this, thisInClass, true, true);

      // (outDuration + 1) | #55 outDuration: 0 crashes animsition on Safari only
      $(options.overlayParentElement)
        .children('.' + options.overlayClass)
        .css({ 'animation-duration' : (outDuration + 1) + 'ms' })
        .removeClass(inClass)
        .addClass(outClass)
        .trigger(__.settings.events.outStart)
        .animateCallback(function(){
          $this.trigger(__.settings.events.outEnd);
          options.transition(url);
        });
    },

    destroy: function(){
      return this.each(function(){
        var $this = $(this);
        $(window).off('.'+ namespace);
        $this
          .css({'opacity': 1})
          .removeData(namespace);
      });
    }

  };

  $.fn.animateCallback = function(callback){
    var end = 'animationend webkitAnimationEnd';
    return this.each(function() {
      var $this = $(this);
      $this.on(end, function(){
        $this.off(end);
        return callback.call(this);
      });
    });
  };

  $.fn.animsition = function(method){
    if ( __[method] ) {
      return __[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
    } else if ( typeof method === 'object' || ! method ) {
      return __.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on jQuery.'+namespace);
    }
  };

}));


  // *** MAILCHIMP AJAX SIGNUP WITH SWEET ALERTS ***
  ajaxMailChimpForm($("#mc-embedded-subscribe-form"));

  // Turn the given MailChimp form into an ajax version of it.
  // If resultElement is given, the subscribe result is set as html to
  // that element.
  function ajaxMailChimpForm($form){
    // Hijack the submission. We'll submit the form manually.
    $form.submit(function(e) {
      e.preventDefault();
      if (!isValidEmail($form)) {
        $form.addClass('error');
        $('#mc-embedded-subscribe-form input[type="email"]').attr("placeholder", "Please provde a valid email address");
      } else {
        $form.removeClass('error');
        $form.addClass('submitting');
        $('#mc-embedded-subscribe-form button[type="submit"]').attr("value", "Submitting...");
        submitSubscribeForm($form);
      }
    });
  }

  // Validate the email address in the form
  function isValidEmail($form) {
    // If email is empty, show error message.
    // contains just one @
    var email = $form.find("input[type='email']").val();
    if (!email || !email.length) {
      return false;
    } else if (email.indexOf("@") == -1) {
      return false;
    }
    return true;
  }

  // Submit the form with an ajax/jsonp request.
  // Based on http://stackoverflow.com/a/15120409/215821
  function submitSubscribeForm($form) {
    $.ajax({
      type: "GET",
      url: $form.attr("action"),
      data: $form.serialize(),
      cache: false,
      dataType: "jsonp",
      jsonp: "c", // trigger MailChimp to return a JSONP response
      contentType: "application/json; charset=utf-8",
      error: function(error){
        // According to jquery docs, this is never called for cross-domain JSONP requests
      },
      success: function(data){
        if (data.result != "success") {
          
          if (data.msg && data.msg.indexOf("already subscribed") >= 0) {
            $('#mc-embedded-subscribe-form input[type="email"]').attr("placeholder", "You’re already subscribed.");
            $('#mc-embedded-subscribe-form button[type="submit"]').attr("value", "Submit");
          }

        } else {
          $form.removeClass('submitting');
          $form.addClass('success');
          $('#mc-embedded-subscribe-form input[type="email"]').attr("placeholder", "You’re signed up. Thank you.");
          $('#mc-embedded-subscribe-form button[type="submit"]').attr("value", "Submitted");
        }
        $('.email_input').val('');
      }
    });
  }

jQuery(function ($) {

  ReplaceObfuscatedEmailAddresses.init();

  $('.slider').slick({
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1200
  });

  $('.menu-toggle').on('click', function () {
    $('.navigation').toggleClass('nav-open');
    $(this).toggleClass('menu-open');
  });

  $('.products .filter-mb .alm-filter--title').on('click', function () {
    $(this).toggleClass('active');
    $(this).next('ul').slideToggle();
  });

  if ($(window).width() < 768) {
    $('.products .alm-filter--title').on('click', function () {
      $(this).toggleClass('active');
      $(this).next('ul').slideToggle();
    });
  }
  else {
    $('.products .filter .alm-filter--title').on('click', function () {
      $(this).toggleClass('active');
      $(this).next('ul').toggleClass('active');
      $('.products .filter .alm-filter--title').not(this).removeClass('active');
      $('.products .filter .alm-filter--title').not(this).next('ul').removeClass('active');
  
      if ($(this).hasClass('active')) {
        $('.overlay, .filter').addClass('active');
      } else {
        $('.overlay, .filter').removeClass('active');
      }
  
    });
    const $menu = $('ul.active');

    $('.overlay').mouseup(e => {
      if (!$menu.is(e.target) 
      && $menu.has(e.target).length === 0) {
        $('.products .filter .alm-filter--title, .products .filter ul, .overlay, .filter').removeClass('active');
      }
    });
  }

  $('.close--popup-message').on('click', function () {
    $('body').removeClass('open--popup-message');
  });

  $(window).on('load',function(){
    $('body').removeClass('preload open--popup-message');
  });

  $('.product-slider').lightSlider({
    gallery: true,
    item: 1,
    loop: true,
    slideMargin: 10,
    thumbItem: 4,
    thumbMargin: 20,
  });

  $(".animsition").animsition({
    inClass: 'fade-in',
    outClass: 'fade-out',
    inDuration: 1200,
    outDuration: 400,
    linkElement: '.animsition-link',
    // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
    loading: true,
    loadingParentElement: 'body', //animsition wrapper element
    loadingClass: 'animsition-loading',
    loadingInner: '', // e.g '<img src="loading.svg" />'
    timeout: false,
    timeoutCountdown: 5000,
    onLoadEvent: true,
    browser: [ 'animation-duration', '-webkit-animation-duration'],
    // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
    // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
    overlay : false,
    overlayClass : 'animsition-overlay-slide',
    overlayParentElement : 'body',
    transition: function(url){ window.location.href = url; }
  });

  $('<button class="focus:outline-none" id="clear-filters">Show all</button>').insertAfter('#alm-filter-5');

  if ($('#clear-filters').length > 0) { 
    let clearBtn = document.getElementById('clear-filters');
    clearBtn.addEventListener('click', function(e){
       almfilters.reset();
    });
  }

});

/*********************
  Cart functionality
  (Cookie storage)
**********************/

var store = {
  read: function(key) {
    var cookie = document.cookie.split('; ').find(row => row.startsWith(key))
    var data = cookie && cookie.split('=')[1]
    if (data) return JSON.parse(atob(data))
    return {}
  },

  write: function(key, o) {
    document.cookie = `${key}=${btoa(JSON.stringify(o))}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`
  },

  clear: function(key) {
    document.cookie = `${key}=; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`
  }
}

var cart = {
  baseShippingPrice: 0,
  totalQuantity: 0,
  totalPrice: 0,
  key: 'wp_favourites',
  toggle: false,
  items: store.read('wp_favourites'),

  update: function() {
    var items = this.items
    this.totalQuantity = Object.keys(items).reduce(function (previous, key) {
      return previous + items[key].quantity;
    }, 0)

    document.dispatchEvent(new CustomEvent('cartUpdate', { detail: this.totalQuantity }))
  },

  save: function () {
    this.update()
    store.write(this.key, this.items)
    document.dispatchEvent(new CustomEvent('updateItems', { detail: this.items }))
  },

  updateBaseShipping: function(cost) {
    this.baseShippingPrice = cost
    this.update()
  },

  addItem: function (obj) {
    if (this.items[obj.id]) {
      if (this.toggle) {
        this.removeItem(obj)
      } else {
        this.items[obj.id].quantity += 1
      }
    } else  {
      obj.quantity = 1;
      this.items[obj.id] = obj
    }
    this.save()
  },

  removeItem: function(obj) {
    if (this.items[obj.id]) this.items[obj.id].quantity -= 1
    if (this.items[obj.id].quantity == 0) delete this.items[obj.id]
    this.save()
  },

  clearItem: function(obj) {
    if (this.items[obj.id]) this.items[obj.id].quantity = 0
    if (this.items[obj.id]) delete this.items[obj.id]
    this.save()
  },

  clear: function() {
    this.items = {}
    this.save()
  }
}

/*********************
  Event handlers
**********************/

document.addEventListener('cartUpdate', function (e) {
  [...document.querySelectorAll('.favourite--total-quantity')].forEach(function(el) {
    el.innerHTML = !e.detail ? '' : e.detail
  })
})

document.addEventListener('updateItems', function (e) {
  [...document.querySelectorAll('.favourite--add')].forEach(function(el) {
    var id = el.getAttribute('data-id')
    if (cart.items[id] && el.innerHTML.indexOf('Add to') > -1) {
      el.innerHTML = 'Added'
      el.style.pointerEvents = 'none'
      document.body.classList.add('open--popup-message')
    }
  });
  [...document.querySelectorAll('.favourite--clear')].forEach(function(el) {
    if (!e.detail[el.getAttribute('data-id')]) {
      el.parentNode.parentNode.parentNode.removeChild(el.parentNode.parentNode)
    }
  });
  [...document.querySelectorAll('.favourite--quantity')].forEach(function(el) {
    try {
      el.innerHTML = e.detail[el.getAttribute('data-id')]['quantity']
    } catch(e) {
      el.parentNode.parentNode.parentNode.parentNode.parentNode.removeChild(el.parentNode.parentNode.parentNode.parentNode)
    }
  })
})

document.body.addEventListener("click", function(e) {
  // Keep cookie and data in sync across pages
  cart.items = store.read('wp_favourites')
  document.dispatchEvent(new CustomEvent('updateItems', { detail: cart.items }))

  if (e.target && e.target.classList.contains('favourite--add')) {
    e.preventDefault()
    cart.addItem({ id: e.target.getAttribute('data-id') })
  }
  else if (e.target && e.target.classList.contains('favourite--remove')) {
    e.preventDefault()
    cart.removeItem({ id: e.target.getAttribute('data-id') })
  }
  else if (e.target && e.target.classList.contains('favourite--clear')) {
    e.preventDefault()
    cart.clearItem({ id: e.target.getAttribute('data-id') })
  }
})

// Trigger an update after a modal opens
try {
  jQuery(function ($) {
    // $(window).on($.modal.BEFORE_BLOCK, function(event, modal) {
    //   document.dispatchEvent(new CustomEvent('updateItems', { detail: window.cart.items }))
    // });
  
    function updateForm() {
      var text = ''
      $('.favourite-product > div').each(function() {
        let name = $('.product-title', this).text()
        let url = $('.product-url', this).attr('href')
        text += `Product: ${name}\nLink: https://collective-au.com${url}\n\n`
      })
      //console.log(text)
      $('textarea[name="your-favourites"]').val(text)
    }

    updateForm()
    document.addEventListener('updateItems', updateForm)
    $('textarea').on('change keyup', updateForm)

  });
} catch(e) { console.log(e) }

document.dispatchEvent(new CustomEvent('updateItems', { detail: cart.items }))